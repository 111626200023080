import React from 'react';
import i18next from 'i18next';
import {Link} from 'react-router-dom';

import './styles.scss';
import Routes from '../Routes';
import logo from '../../assets/img/logo.png';
import home from '../../assets/img/home.svg';
import help from '../../assets/img/help.svg';
import {LOCAL_GUIDES_LIST} from "../../constants/urls";

class App extends React.Component {
  componentWillMount() {
    this.setLanguage(localStorage.getItem('lang') || 'en');
  }

  render() {
    return (
      <>
        <header className='header'>
          <div className='header__navigation'>
            <Link to={LOCAL_GUIDES_LIST} className='button'>
              <img src={home} alt="home" className='button__image'/>
              {i18next.t('home')}
            </Link>
            <a href='#/' className='button'>
              <img src={help} alt="help" className='button__image'/>
              {i18next.t('contact')}
            </a>
          </div>
          <div className='logo__wrapper'>
            <img src={logo} alt="logo" className='logo__image'/>
          </div>
        </header>
        <Routes />
      </>
    );
  }

  setLanguage(language) {
    i18next.init({
      lng: language,
      resources: require(`../../i18n/${language}.json`)
    });
  }
}

export default App;

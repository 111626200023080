import React, {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router';

import {LOCAL_GUIDE, LOCAL_GUIDE_STEP, LOCAL_GUIDES_LIST} from '../../constants/urls';

class Routes extends React.Component {
  render() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route
            exact
            path={LOCAL_GUIDES_LIST}
            component={lazy(() => import('../Guides'))}
          />
          <Route
            exact
            path={LOCAL_GUIDE}
            component={lazy(() => import('../Guide'))}
          />
          <Route
            exact
            path={LOCAL_GUIDE_STEP}
            component={lazy(() => import('../Guide'))}
          />
          <Route
            exact
            component={() => <div>Not Found</div>}
          />
        </Switch>
      </Suspense>
    );
  }
}

export default Routes;

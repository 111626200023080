import {combineReducers} from 'redux';

// Reducers
import tree from './tree';

const appReducer = combineReducers({
  tree,
});

const rootReducer = (state, action) => {
	return appReducer(state, action);
};

export default rootReducer;

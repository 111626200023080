import actionTypes from '../constants/actionsTypes';

const initialState = {
  tree: {},
  loaded: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.tree.get.success:
      return {
        ...state,
        tree: action.payload.tree,
        loaded: true,
      };
    default:
      return state;
  }
}

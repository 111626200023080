import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import reduxThunk from 'redux-thunk';
import {BrowserRouter} from 'react-router-dom';
import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';

import './styles.scss';
import App from './components/App';
import rootReducer from './reducers';
import * as serviceWorker from './serviceWorker';
import {setDefaultAxios, setAuthorizationHeader} from './helpers/axios';

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxThunk)),
);

setDefaultAxios(store.dispatch);
setAuthorizationHeader(localStorage.getItem('success_token'));
localStorage.setItem('build v:', '0.0.1');

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
// Local
export const LOCAL_GUIDES_LIST = '/';
export const LOCAL_GUIDE = '/:guideId';
export const LOCAL_SET_GUIDE = guideId => `/${guideId}`;
export const LOCAL_GUIDE_STEP = '/:guideId/:stepId';
export const LOCAL_SET_GUIDE_STEP = (guideId, stepId) => `/${guideId}/${stepId}`;

// API
const GUIDE_SERVICE = `/guide-service`;
export const API_GUIDES_LIST = `${GUIDE_SERVICE}/guide/`;
export const API_OPEN_GUIDE = guideId => `${GUIDE_SERVICE}/guide/${guideId}/open`;
export const API_GET_TREE = `${GUIDE_SERVICE}/tree`;
export const API_CHANGE_NAVIGATION = way => `${GUIDE_SERVICE}/navigation/${way}`;
export const API_SET_NAVIGATION_STEP = stepId => `${GUIDE_SERVICE}/navigation/step/${stepId}`;
export const API_GET_DESCRIPTION = `${GUIDE_SERVICE}/description`;
export const API_GET_MEDIA = `${GUIDE_SERVICE}/media`;
export const BASE_MEDIA_URL = BASE_API_URL;

import axios from 'axios';
import {BASE_API_URL} from '../constants/urls';

export const setDefaultAxios = dispatch => {
  axios.defaults.baseURL = BASE_API_URL;
	axios.interceptors.response.use(
		response => response,
		error => {
			// if (error.response.status === 401) {
			// 	// logout
			// }
			return Promise.reject(error);
		},
	);
};

export const setAuthorizationHeader = (token: string | null): void => {
	axios.defaults.headers.Authorization = token;
};

export const removeAuthorizationHeader = (): void => {
	delete axios.defaults.headers.Authorization;
};
